export default {
    //SITES
    //#region
    sites: [
      {text: 'Home', url: ''},
      {text: 'Bottle Production', url: 'bottle-production', metaName:'',metaContent:''},
      {text: 'Bucket Production', url: 'bucket-production', metaName:'',metaContent:''},
      {text: 'Bottle Blowing Machine production', url: 'blowing-machine', metaName:'',metaContent:''},
      {text: 'Attachments', url: 'attachments',show: false, metaName:'',metaContent:''},
      {text: 'Data privacy', url: 'privacy', right:true, metaName:'',metaContent:''},
      {text: 'Complaints', url: 'complaints', right:true, metaName:'',metaContent:''},
      {text: 'Tenders', url: 'tenders', right:true, metaName:'',metaContent:''}
    ],
    //#endregion
    //REGULAR EXPRESSIONS
    //#region
    re1: 'Enquiry',
    ty:'Thank you!',
    //#endregion
    //MAIN VIEW
    //#region
    h1: 'Thirty years, ',
    h2: 'of excellence',
    h3: ' guaranteed',
    h4: 'Our advanced technological solutions serve to enhance the convenience, productivity, and cost-efficiency of our customers for many years, at a competitive price.',
    h5: 'Machines installed',
    h6: 'Years of experience',
    h7: 'Product category',
    h8: 'Colleague',
    hb1: 'Bottle blowing machine',
    hb2: 'Among the largest manufacturers in Europe',
    hb3: 'Bottle production',
    hb4: 'The expert of custom PET bottle design',
    hb5: 'Excavator and loader attachments',
    hb6: 'Professional buckets are made of these',
    hb7: 'Read more →',
    h9: 'Get a quote!',
    au1: 'About us',
    au2: 'Solutions across industries',
    au3: 'Comprehensive professional experience in the plastic packaging industry, machinery manufacturing, metalworking, and the production of earthmoving machinery attachments.',
    au4: 'Relying on our development and innovation strength, the performance of our motivated colleagues, and the knowledge of our dedicated and recognized experts, we develop our products and technology to contribute to our customers\' growth and our shared success.',
    at: 'Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable .',
    h10: 'poliol attachment manufacturing',
    wfd1: 'Dare with us',
    rmb1: 'More info',
    rmb2: 'Get a quote!',
    wfd2: 'Serie and custom production',
    wfd3: 'Catalog products available immediately off the shelf. Custom solutions under short shipping time.\'',
    wfd4: 'Poliol Service Assistance ™',
    wfd5: 'Constant availability, 24-48 h dispatch. Replacement bucket, attachment during the repair period.',
    wfd6: 'Precise to the smallest details ',
    wfd7: 'In-house developed and manufactured work cylinders, and quick coupler systems.',
    wfd8: 'Details',
    h11: 'poliol bottle blowing machine production',
    bmwfd1: 'machine production',
    bmwfd2: 'user-centered innovation',
    bmwfd3: 'Our main priority in the design and manufacturing of POLIOL blow molding machines, based on over 20 years of purely Hungarian know-how knowledge and manufacturing experience, is to build machines that provide lasting value and stable production capacity for our customers.',
    ml1: 'Our locations',
    ml2: 'Locations, 8 countries where the POLIOL manufactured Bottle blowing machines operate, along with absolute partner satisfaction.',
    ml3: 'Romania',
    ml4: 'Algeria',
    ml5: 'Hungary',
    ml6: 'Belgium',
    ml7: 'Croatia',
    ml8: 'North Macedonia',
    ml9: 'Moldavia',
    ml10: 'Serbia',
    pmp1: 'Among the largest manufacturers in Europe',
    pmp2: 'Reliable operation',
    pmp3: 'With the usage of the highest quality electronic and pneumatic parts.',
    pmp4: 'Hydraulic preform filler',
    pmp5: 'for support of full automatic operation',
    pmp6: 'Strong frame',
    pmp7: 'Our machines outstanding stability starts with building a strong frame',
    pmp8: 'Low upkeeping costs',
    pmp9: 'Thanks to the substancial service requirement, and our power-efficient solutions',
    h12: 'Special offer! Get your machine now, from stock!',
    h13: 'Why choose us?',
    h14: 'Available immediately: No waiting time, you can start producing in a matter of days.',
    h15: 'Reliability and quality: Our machines has been proving their excellence for years, in all the fields.',
    h16: 'Favorable prices: We offer our machines at a special price, so you can enjoy the highest technological solutions.',
    h17: 'Don\'t miss the opportunity! Our machines are still available from stock at this point.',
    h18: 'poliol bottle production',
    bpm0: 'Bottle production',
    bpm1: '10 billion bottle experience',
    bpm2: 'experience',
    bpm3: 'From idea to your doorstep',
    bpm4: 'In-house custom design - 3D modelling - production - labeling, screening - shipping.',
    bpm5: 'Details',
    bpm6: 'Choose online',
    bpm7: 'Webshop products with immediate availability. Custom solutions with short shipping time.',
    bpm8: 'Bottle Webshop',
    bpm9: 'Available in all sizes',
    bpm10: 'Based on demand, we design and manufacture products of various sizes and shapes - ranging from 40 ml bottles to 12 l bottles, cylindrical or square, even products with unique designs.',
    bpm11: 'Details',
    cup1: 'contact',
    cup2: 'Get in touch!',
    cup3: 'Ready to answer your questions',
    cup4: 'message',
    cup5: 'Send a message:',
    cup6: 'Name*',
    cup7: 'Mobile*',
    cup8: 'Message (optional)',
    cup9: 'Send',
    cup10: 'No name given',
    cup11: 'No email address provided',
    cup12: 'No contact phone provided',
    cup13: 'Privacy Policy',
    cup14: 'I am aware of the the privacy policy',
    cup15: 'You need to accept the privacy policy',
    fc1: 'Let\'s help you take it to the next level',
    fc2: 'Excavator attachment -, bucket production',
    fc3: 'János Tősi',
    fc4: 'Blowing Machine production',
    fc5: 'Csaba Lukács',
    fc6: 'Bottle production',
    fc7: 'Adrienn Bíró',
    fc8: 'central',
    fc9: 'All rights reserved © Poliol Kft. - 2024',
    fc10: 'address',
    fc11: 'Privacy Policy',
    //#endregion

    //BOTTLE BLOWING SITE
    //#region
    //BOTTLE BLOWING REGULAR
    bb1: 'Fields where our bottles provide daily',
    //BOTTLE BLOWING BANNER
    bbb1: 'HOTFILL technology',
    bbb2: 'Maintain your competitive edge with our revolutionary HOTFILL technology. Maximum heat resistance and safety. Learn more about our state-of-the-art bottle manufacturing methods!',
    bbb3: 'Maintain your competitive edge with our revolutionary HOTFILL technology. Maximum heat resistance and safety.',
    bbb4: 'Sustainability',
    bbb5: 'Sustainable PET bottle manufacturing with eco-friendly solutions. Explore how we protect the planet with our products!',
    bbb6: 'Custom design',
    bbb7: 'From idea to your doorstep, we handle the entire bottle manufacturing process in-house. Our team manages everything in one place, from uniquely designed concepts, to the final production. We efficiently and effectively transform your designs into high-quality PET bottles.',
    bbb8: 'From idea to your doorstep, we handle the entire bottle manufacturing process in-house. Our team manages everything in one place, from uniquely designed concepts, to the final production.',
    bbb9: 'Labeling, screening',
    bbb10: 'Enhance the appeal of PET bottles with our labeling and screen printing services! Excellent quality, customized options to make your products stand out on the shelves!',
    bbb11: 'Enhance the appeal of PET bottles with our labeling and screen printing services!',
    bbmProdProperties: ['No. of cavities', 'Maximal volume', 'Maximal production capacity'],
    //BOTLLE BLOWING PRODUCT CATEGORIES
    bbpc1: 'Chemical industry',
    bbpc2: 'Durable and secure PET bottles for storing chemicals and cleaning agents.',
    bbpc3: 'Food industry',
    bbpc4: 'Hygienic and secure PET bottles, that keep the freshness and quality of food products.',
    bbpc5: 'Mineral oil, lubricants',
    bbpc6: 'Resistant PET bottles that safely store and transport mineral oils and lubricants',
    bbpc7: 'Cosmetics',
    bbpc8: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products.',
    //BOTTLE BLOWING TITLE TEXT IMAGE
    bbtti1: 'why the POLIOL bottles?',
    bbtti2: 'THINK OF SOMETHING.. WE CAN MAKE IT REAL',
    bbtti3: 'Our excellently manufactured, self-designed, and developed plastic-based bottles have consistently proved over the past years.',
    bbtti4: 'Based on the unique requirements of numerous small and medium-sized enterprises, we design and manufacture bottles of various sizes and shapes - from 40 ml bottles to 12 l bottles, cylindrical or square, even bottles with a unique design language - mainly suitable for storing various liquids such as beverages, food, and chemicals. One of our greatest successes is that our 5-liter bottle designed based on our own experience and knowledge, currently used by Szentkirályi Ásványvíz, has received an AWARD OF EXCELLENCE.',
    //BOTTLE BLOWING PRODUCT HEROES
    bbpc11: 'HOTFILL',
    bbpc12: 'Freshness in every drop',
    bbpc13: 'Experience the benefits of our new Hotfill products, allowing high-temperature liquid filling to preserve fresh flavors and extend shelf life. Discover how we elevate PET packaging technology to a new level. Be a part of the Hotfill revolution!',
    bbpc14: ['Juice', 'Tea', 'Energy drinks', 'Honey', 'Smoothie'],
    bbpc21: 'Food products',
    bbpc22: 'In service of hygienic storage for 30 years',
    bbpc23: 'Hygienic and safe PET bottles that preserve the freshness and quality of food products. Explore our solutions for safe storage!',
    bbpc24: ['Water', 'Cooking oil', 'Milk', 'Honey', 'syrup'],
    bbpc31: 'Alcoholic beverage',
    bbpc32: 'Quality in the bottle',
    bbpc33: 'High-quality PET bottles for spirits, preserving the flavors and freshness. Choose the best solution to serve your customers in premium quality!',
    bbpc34: ['Beer', 'Wine', 'Spirits', 'Cider'],
    bbpc41: 'Cosmetics',
    bbpc42: 'Elegance and durability in one bottle',
    bbpc43: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products. Make your brand appealing with our excellent packaging solutions!',
    bbpc44: ['Cremes', 'Body lotions', 'Shower gels', 'Shampoo'],
    bbpc51: 'Mineral oil, lubricants',
    bbpc52: 'Secure storage and transport',
    bbpc53: 'Resistant PET bottles that safely store and transport mineral oils and lubricants. Trust us with the packaging to ensure your products remain in perfect condition under all circumstances!',
    bbpc54: ['Motor oil', 'Brake fluid', 'Lubricants', 'Antifreeze'],
    bbpc61: 'Chemical, cleaning agent',
    bbpc62: 'Safety and reliability',
    bbpc63: 'Durable and safe PET bottles for storing chemicals and cleaning agents. Choose reliability and quality with our products!',
    bbpc64: ['Cleaning agents', 'Disinfectants', 'Washing liquids', 'Industrial chemicals'],

    //#endregion
    //BUCKET PRODUCTION SITE
    //#region
    //BUCKET PRODUCTION REGULAR
    bp1: 'Excavator and loader attachments',
    //BUCKET PRODUCTION BANNER
    bpb1: 'Heavy-duty mining bucket',
    bpb2: 'Innovative solutions, mining buckets with exceptional hardness and durability!',
    bpb3: '7 m³ high dump bucket',
    bpb4: 'We design and manufacture custom solutions tailored to specific customer needs!',
    bpb5: 'Product page',
    //BUCKET PRODUCTION TITLE TEXT IMAGE
    bptti1: 'why POLIOL bucket attachments',
    bptti2: 'Dare with us!',
    bptti3: 'Poliol has over 30 years of experience in manufacturing buckets and attachments. Our company offers innovative solutions that guarantee durability and reliability across all industries.',
    bptti4: 'We offer self-developed and manufactured work cylinders and quick coupler systems. These innovations provide reliability and efficiency for a wide range of applications. Our team has professional tools and knowledge for the refurbishment and modernization of buckets and attachments, ensuring equipment durability and efficiency across various industries. Our products are made from HARDOX materials known for their exceptional strength and durability, ensuring long-term performance. But not only reliability characterizes us, but also swiftness: we deliver catalog products within as little as 2 weeks!',
    //#endregion
    //BOTTLE BLOWING MACHINE PRODUCTION
    //#region
    //BOTTLE BLOWING MACHINE PRODUCTION REGULAR
    bbmp1: 'Our Blowing Machine lines',
    bbmp2: 'Our Blowing Machines',
    bbmp3: 'Additional Machines',
    bbmp4: 'Hide Products',
    //BOTTLE BLOWING MACHINE PRODUCTION BANNER
    bbmpb1: 'PA-2 ECO+',
    bbmpb2: 'The most efficient tool to replace semi-automatic machines.',
    bbmpb3: 'PE-UNI',
    bbmpb4: '30 years of development and reliability in a new attire.',
    //BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
    bbmpc1: 'Dairies',
    bbmpc2: 'With our machines, you can produce premium quality PET bottles that ensure the safe and long-term storage of dairy products.',
    bbmpc3: 'Breweries, Wineries',
    bbmpc4: 'Innovation above all: manufacture innovative, trendy, and fancy bottles in-house! Product quality and durability!',
    bbmpc5: 'Soft Drink Manufacturing',
    bbmpc6: 'As a soft drink manufacturer, you can produce durable and aesthetic PET bottles. Enhance the attractiveness and safety of your products in-house with our machines!',
    bbmpc7: 'Cosmetic Companies',
    bbmpc8: 'Enhance your brand appeal with durable and elegant PET bottles that ensure the safety and freshness of your products. Produce PET bottles in-house with our machines!',
    bbmpc9: 'Oil Plants',
    bbmpc10: 'Resilient, quality bottles meeting the strictest industrial requirements. Ensure the integrity of the products in-house!',
    //BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
    bbmptti1: 'why choose Poliol?',
    bbmptti2: 'The most customizable bottle blowing machines in the world',
    bbmptti3: 'Poliol’s bottle blowing machines represent the pinnacle of customization and reliability. With over 30 years of design and manufacturing experience, we ensure that our machines meet even the strictest of industrial requirements.',
    bbmptti4: 'Through our innovative solutions, our machines are not only energy-efficient and space-saving, but also offer quick changeover options, maximizing operational efficiency. The servo motor operation and hydraulic preform filling system guarantee precise and reliable production. For over three decades, we have specialized in linear bottle blowing machines, offering unparalleled expertise and reliability in the market. Our machines are characterized by stable, robust construction, energy efficiency, and space-saving design, ensuring seamless integration into your production environment. Choose Poliol’s bottle blowing machines and enjoy fully customizable, high-quality solutions!',
    //BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
    bbmppc11: '30 years of quality, just got new.',
    bbmppc12: 'The more versatile UNI machine family stands out from the product list with its fully automatic operation. It guarantees quick changeovers, stronger blowing pressure, and larger bottle volume for our customers.',
    bbmppc13: 'POLIOL Finance™   Leasing Options: Flexible leasing options to meet your financial needs.',
    bbmppc14: ['0.2L - 19L producible volume', 'tool-free changeover in 10 minutes', 'HOTFILL capability', 'Up to 40 Bar blowing pressure for the toughest of challenges'],
    bbmppc21: 'The most efficient tool to replace your semi-automatic machines.',
    bbmppc22: 'In the design of our economy series blowing machines, energy efficiency and sustainability were the primary considerations, while maintaining the maximum level of quality and reliable operation characteristic of our manufacturing.',
    bbmppc23: 'POLIOL Finance™   Leasing Options: Flexible leasing options to meet your financial needs.',
    bbmppc24: ['Fully automatic operation', 'Low energy consumption', 'Air recovery system', 'Compact design', 'Quick, easy installation'],
    bph: ' pcs/hour',
    //#endregion

    //PRODUCTGROUPS
    //#region

    prodGroupLocale:
        [
          {
            id: 'buckets',
            name: 'Attachments',
            types: [
              {
                id: 'faipar',
                name: 'Wood Industry, Agriculture',
                subtitle: 'Durable and efficient buckets and attachments for the wood industry and agriculture. 30 years of experience, high-performance design and manufacturing line!',
              },
              {
                id: 'foldmunka',
                name: 'Large-scale Earthmoving',
                subtitle: 'Strong buckets and attachments for large-scale earthmoving. Increase your efficiency and minimize downtime!',
              },
              {
                id: 'kozmu',
                name: 'Utility Construction',
                subtitle: 'Reliable tools for utility construction. Order today and take advantage of our fast delivery service!',
              },
              {
                id: 'varos',
                name: 'Urban Maintenance',
                subtitle: 'Professional buckets and attachments for efficient urban maintenance. We design, manufacture, and offer reliability!',
              },
              {
                id: 'banyaszat',
                name: 'Mining',
                subtitle: 'Tools made from durable Hardox material for the needs of stone and rock mining. Discover our products that resist even the toughest conditions!',
              },
              {
                id: 'gyorscserelok',
                name: 'Quick Couplers',
                subtitle: 'Fast and reliable quick couplers for maximum efficiency. Choose from our innovative solutions, or let\'s design something completely unique together!',
              },
            ]
          },
          {
            id: 'bottles',
            name: 'Bottles',
            types: [
              {
                id: 'vegyipar',
                image: 'vegyipar.webp',
                name: 'Chemical Industry',
                subtitle: 'Durable and safe PET bottles for storing chemicals and cleaning agents.',
              },
              {
                id: 'elelmiszeripar',
                image: 'Uditogyartas.webp',
                name: 'Food Industry',
                subtitle: 'Hygienic and safe PET bottles that preserve the freshness and quality of food products.',
              },
              {
                id: 'asvanyi-olaj-kenoanyag',
                image: 'Olajuzemek.webp',
                name: 'Mineral Oil, Lubricant',
                subtitle: 'Resistant PET bottles that safely store and transport mineral oils and lubricants.',
              },
              {
                id: 'kozmetikai',
                image: 'Kozmetikai-cegek.webp',
                name: 'Cosmetics',
                subtitle: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products.',
              },
            ]
          },
        ],
    //#endregion
    //PRODUCTS
    //#region
    prodLocale: [
      {
        "id": "rezsu-kanal",
        "name": "Slope Bucket",
        "properties": [
          "HARDOX 500 cutting edge",
          "Strong, custom bucket profile",
          "Available in various profiles"
        ],
        "usage": "Recommended for slope cutting, cleaning of ditches and beds, and surface cutting.",
        "text": "The fixed slope bucket is a perfect solution for earthworks, especially for creating ditches, slopes, and embankments. The tool is of high quality and offers outstanding wear resistance, ensuring a long lifespan. It enables more precise and efficient work. Choose the fixed slope bucket for accurate and reliable earthworks!"
      },
      {
        "id": "hidraulikus-rezsu-kanal",
        "name": "Hydraulically Tiltable Slope Bucket",
        "properties": [
          "HARDOX 500 cutting edge and side edge",
          "Massive, S355 steel bucket body",
          "Multiple reinforcing ribs",
          "Modern bucket profile",
          "Extended smoothing surface at no extra cost",
          "Precise work with adjustable flow rate to protect hydraulic cylinders and ensure precision"
        ],
        "usage": "Recommended for all tasks where uneven terrain cannot be managed by the machine alone.",
        "text": "The hydraulic slope bucket revolutionizes the shaping of slopes and ditches, enabling precise and efficient work on any job site. The innovative hydraulic system allows for easy and accurate maneuvering, providing high-performance earthworks for the operator. Made of high-quality materials, the bucket ensures exceptional wear resistance and long service life for reliable use over time.\nChoose the hydraulic version for wide applicability and flexible earthworks! Trust it for the most precise tasks with a tool representing the peak of performance and reliability on every job site!"
      },
      {
        "id": "melyaso-kanal-korom-nelkul",
        "name": "Deep Digging Bucket with Cutting Edge",
        "properties": [
          "Bucket profile designed based on operator feedback",
          "HARDOX 500 cutting edge and side edge",
          "Available in standard, reinforced, and extra-reinforced versions",
          "For all types of excavators"
        ],
        "usage": "Recommended for most subsoil digging and loading tasks.",
        "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
      },
      {
        "id": "rezsu-kanal-varos",
        "name": "Slope Bucket",
        "properties": [
          "HARDOX 500 cutting edge",
          "Strong, custom bucket profile",
          "Available in various profiles"
        ],
        "usage": "Recommended for slope cutting, cleaning of ditches and beds, and surface cutting.",
        "text": "The fixed slope bucket is a perfect solution for earthworks, especially for creating ditches, slopes, and embankments. The tool is of high quality and offers outstanding wear resistance, ensuring a long lifespan. It enables more precise and efficient work. Choose the fixed slope bucket for accurate and reliable earthworks!"
      },
      {
        "id": "melyaso-kanal",
        "name": "Deep Digging Bucket with Teeth",
        "properties": [
          "Bucket profile designed based on operator feedback",
          "HARDOX 500 cutting edge and side edge",
          "Optional tooth system",
          "Equipped with wear strips on the bucket body",
          "Available in standard, reinforced, and extra-reinforced versions",
          "For all types of excavators"
        ],
        "usage": "Recommended for most subsoil digging and loading tasks.",
        "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
      },
      {
        "id": "trapez-kanal-foldmunka",
        "name": "Trapezoid Bucket",
        "properties": [
          "500 HB cutting edge",
          "Available with hydraulically tilting and adjustable side edges",
          "Profile designed based on operator feedback"
        ],
        "usage": "Recommended for ditch digging - symmetrical and asymmetrical ditch profiles that do not require additional work.",
        "text": "Discover the versatility of the trapezoid bucket, which offers ideal solutions for digging, shaping, and maintaining ditches and canals! Made from high-quality material and featuring exceptional wear resistance, it guarantees long life and reliability during the work process. Elevate your work standards with the trapezoid bucket, allowing for more precise and efficient results under all conditions. The best choice when quality and performance matter!"
      },
      {
        "id": "melyaso-kanal-korom-nelkul-kozmu",
        "name": "Deep Digging Bucket with Cutting Edge",
        "properties": [
          "Bucket profile designed based on operator feedback",
          "HARDOX 500 cutting edge and side edge",
          "Available in standard, reinforced, and extra-reinforced versions",
          "For all types of excavators"
        ],
        "usage": "Recommended for most subsoil digging and loading tasks.",
        "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
      },
      {
        "id": "trapez-kanal-kozmuepites",
        "name": "Trapezoid Bucket",
        "properties": [
          "500 HB cutting edge",
          "Available with hydraulically tilting and adjustable side edges",
          "Profile designed based on operator feedback"
        ],
        "usage": "Recommended for ditch digging - symmetrical and asymmetrical ditch profiles that do not require additional work.",
        "text": "Discover the versatility of the trapezoid bucket, which offers ideal solutions for digging, shaping, and maintaining ditches and canals! Made from high-quality material and featuring exceptional wear resistance, it guarantees long life and reliability during the work process. Elevate your work standards with the trapezoid bucket, allowing for more precise and efficient results under all conditions. The best choice when quality and performance matter!"
      },
      {
        "id": "ripper",
        "name": "Ripper Tooth",
        "properties": [
          "Seat made of S355 structural steel",
          "HARDOX 500 sheath",
          "Easily replaceable tooth system",
          "Continuously sharpening design",
          "Compatible with any fastener"
        ],
        "usage": "Recommended for loosening hard, saline soils, and uprooting stones and stumps buried in the ground.",
        "text": "Discover the versatility of the ripper tooth, which is a crucial tool not only for mini machines but also for the largest tracked excavators! This innovative tool allows for easy and effective loosening of hard, saline soil, enabling efficient and effective material handling afterward. Employ a solution that guarantees efficiency and effectiveness on every work site!"
      },
      {
        "id": "hidraulikus-rezsu-kanal-kozmu",
        "name": "Hydraulically Tiltable Slope Bucket",
        "properties": [
          "HARDOX 500 cutting edge and side edge",
          "Massive, S355 steel bucket body",
          "Multiple reinforcing ribs",
          "Modern bucket profile",
          "Extended smoothing surface at no extra cost",
          "Precise work with adjustable flow rate to protect hydraulic cylinders and ensure precision"
        ],
        "usage": "Recommended for all tasks where uneven terrain cannot be managed by the machine alone.",
        "text": "The hydraulic slope bucket revolutionizes the shaping of slopes and ditches, enabling precise and efficient work on any job site. The innovative hydraulic system allows for easy and accurate maneuvering, providing high-performance earthworks for the operator. Made of high-quality materials, the bucket ensures exceptional wear resistance and long service life for reliable use over time.\nChoose the hydraulic version for wide applicability and flexible earthworks! Trust it for the most precise tasks with a tool representing the peak of performance and reliability on every job site!"
      },
      {
        "id": "erositett-melyaso-kanal",
        "name": "Mining Bucket",
        "properties": [
          "Hardox HiTuf 500 bucket body",
          "Welds made with wear-resistant special filler material",
          "For the toughest mining tasks",
          "Custom equipment levels"
        ],
        "usage": "Recommended for the toughest mining jobs and demands. Long-term solution available at local prices.",
        "text": "The mining bucket is specifically designed for use in mining and heavy industrial environments, handling large quantities of material. The tool is high quality and offers outstanding wear resistance, ensuring a long lifespan. It helps carry out serious material handling tasks efficiently. Choose our mining bucket for reliable and durable material handling!"
      },
      {
        "id": "erositett-rakodo-kanal",
        "name": "Heavy-Duty Loader Bucket (HDX)",
        "properties": [
          "Designed for the heaviest mining loading operations",
          "Custom sizes for all machine types",
          "Every part of the bucket can be equipped with wear segments and segments",
          "Custom bucket profile and cutting edge shape selection"
        ],
        "usage": "Mining version specifically for mining loading tasks. Custom equipment adjusted to customer needs.",
        "text": "Discover the pinnacle of performance and durability with the reinforced loader bucket, designed specifically for the toughest mining and industrial challenges. The robust design and oversized components deliver outstanding performance even under the most extreme working conditions. The wear-exposed parts of the bucket are protected with special wear-resistant plates, while the extra reinforced side edge ensures maximum protection and durability."
      },
      {
        "id": "rakodo-kanal",
        "name": "Loader Bucket",
        "properties": [
          "For all types of loaders",
          "Available with HARDOX 500 bucket body",
          "Optional tooth set available in various sizes",
          "Available in both opening and high-dump versions",
          "Produced in standard, reinforced, and mining versions"
        ],
        "usage": "Primarily recommended for loading tasks, from small to large quantities. For all machine types. Depending on the material, available with a cutting edge or tooth equipment.",
        "text": "The loader bucket is versatile for loading and transporting materials such as sand, gravel, and soil. Custom-made to meet your needs, the tool is high quality and reliable. It enhances work efficiency with its reliability and durability. Choose our loader bucket for all your loading tasks!"
      },
      {
        "id": "ripper-banyaszat",
        "name": "Ripper Tooth",
        "properties": [
          "Seat made of S355 structural steel",
          "HARDOX 500 sheath",
          "Easily replaceable tooth system",
          "Continuously sharpening design",
          "Compatible with any fastener"
        ],
        "usage": "Recommended for loosening hard, saline soils, and uprooting stones and stumps buried in the ground.",
        "text": "Discover the versatility of the ripper tooth, which is a crucial tool not only for mini machines but also for the largest tracked excavators! This innovative tool allows for easy and effective loosening of hard, saline soil, enabling efficient and effective material handling afterward. Employ a solution that guarantees efficiency and effectiveness on every work site!"
      },
      {
        "id": "hidraulikus-kotro-XCMG-gyorscserelo",
        "name": "Hydraulic/Mechanical Excavator Quick Coupler",
        "properties": [
          "A key tool for the versatility of the excavator. Saving time, human and machine power in hydraulic and mechanical versions for all machine types",
          "A key tool for the versatility of the excavator",
          "Saving time, human and machine power",
          "Available in both hydraulic and mechanical versions!",
          "For all machine types"
        ],
        "usage": "Recommended for excavators used for multiple purposes with frequent tool changes. In today's developing world, it is almost an essential accessory for an excavator.",
        "text": "The hydraulic excavator quick coupler allows for rapid tool changes, significantly enhancing the machine's versatility. Custom-made to meet specific needs, the tool is high quality and durable. It allows you to complete different earthworks more quickly and efficiently. Choose the hydraulic excavator quick coupler for flexible and quick work!"
      },
      {
        "id": "hidraulikus-rakodo-VOLVO-gyorscserelo",
        "name": "Hydraulic Loader Quick Coupler",
        "properties": [
          "Enables multi-purpose use of the loader."
        ],
        "usage": "Recommended for loaders used for various purposes, with frequent tool changes.",
        "text": "Designed for the construction of drainage ditches, canals, and slopes."
      },
      {
        "id": "balavilla",
        "name": "Bale Fork",
        "properties": [
          "Own profile attachment",
          "Simple design and cost-effective pricing",
          "With replaceable spikes",
          "For any fastener"
        ],
        "usage": "Recommended for handling bales in the agricultural sector.",
        "text": "The bale fork is excellent for moving and handling straw bales, hay bales, and other agricultural materials. Custom-made to meet specific needs, the tool is reliable and durable. It facilitates and enhances the efficiency of bale handling in daily use."
      },
      {
        "id": "tragyavilla",
        "name": "Manure Fork",
        "properties": [
          "Robust, simple body made of S355 structural steel",
          "Replaceable fork tine",
          "Hydraulic clamping crocodile version available",
          "Also available in a simple, lower fork tine version"
        ],
        "usage": "Recommended for agricultural work, manure handling, and loading fibrous plant waste.",
        "text": "The manure fork is excellent for handling and loading manure in agricultural areas. Custom-made to meet specific needs, the tool is high quality, reliable, and durable. It allows for easy and efficient manure handling tasks. Be a part of the efficient mechanization of agriculture."
      },
      {
        "id": "konnyuanyag-kanal",
        "name": "Light Material Bucket",
        "properties": [
          "Spill guard bucket profile, Hardox 500 cutting edge, large volume without unnecessary weight, optional bolt-on cutting edge",
          "Spill guard bucket profile",
          "HARDOX 500 cutting edge",
          "Large volume, without unnecessary weight",
          "Optional bolt-on cutting edge"
        ],
        "usage": "Recommended for loading large quantities of agricultural spreadable materials, bulk feed, in both fixed and high-dump versions.",
        "text": "The light material bucket is ideal for loading and moving light materials such as wood chips, compost, and litter. The tool is high quality and offers outstanding wear resistance, ensuring a long lifespan. It is a perfect choice for efficient and precise work. Choose the light material bucket for efficient material handling tasks!"
      },
      {
        "id": "magasuritesu-kanal",
        "name": "High Dump Bucket",
        "properties": [
          "Massive bucket body",
          "HARDOX 500 cutting edge",
          "With custom spill guard design",
          "Actual cubic meter calculation"
        ],
        "usage": "Recommended for high dumping tasks.",
        "text": "The high dump bucket is designed for the largest industrial tasks, enabling the fast and efficient handling of large quantities of material. The robust design and extra reinforced structure ensure maximum performance even under the heaviest load. Made from high-quality material, the bucket guarantees exceptional wear resistance, providing a long lifespan for continuous use.\nDon't let big tasks stop you - choose the high dump bucket for efficiency and reliability! Experience peak performance on your work sites with a tool that ensures the highest productivity and results under all conditions!"
      },
      {
        "id": "raklapvilla",
        "name": "Pallet Fork",
        "properties": [
          "Wide load capacity range",
          "For all types of machines, not just loaders",
          "CE certification"
        ],
        "usage": "Recommended primarily for moving palletized goods, as well as any other load that matches the load capacity.",
        "text": "The pallet fork is ideal for the safe and efficient movement of pallets and different loads. The tool is high quality, reliable, and durable. Custom-made according to specific needs, the attachment enables easier and more precise execution of loading tasks. Choose the pallet fork for excellent quality and efficiency!"
      },
      //BLOWING MACHINES
        {
          id: 'pap1eco',
          name: 'PAP-1 ECO',
          overlayText: 'The PA machine family, except for the stretch operated by a servomotor, consists of machines operated by pneumatic cylinders. The PAP machines are specifically designed for producing small-volume bottles with minimal space and energy requirements. Only this ECO type can be ordered in a semi-automatic configuration, with manual preform feeding and manual bottle extraction. It is a simple machine.'
        },
        {
          id: 'pap1',
          name: 'PAP-1',
          overlayText: 'The PA machine family, except for the stretch operated by a servomotor, consists of machines operated by pneumatic cylinders. The PAP machines are specifically designed for producing small-volume bottles with minimal space and energy requirements. This type is fully automatic. The machine’s control is capable of serving a filling machine in online mode, placing the finished bottles on a conveyor belt with an additional unit.'
        },
        {
          id: 'pap1hotFill',
          name: 'PAP-1 HOT FILL',
          overlayText: 'The PAP-1 machine is equipped with an additional package that allows the production of hot-fillable bottles. The machine can easily switch between HOTFILL or conventional bottle production.'
        },
        {
          id: 'pa1eco',
          name: 'PA-1 ECO',
          overlayText: 'The PA machine family, except for the stretch operated by a servomotor, consists of machines operated by pneumatic cylinders. This type is fully automatic. The machine’s control is capable of serving a filling machine in online mode, placing the finished bottles on a conveyor belt with an additional unit. It is a simple machine.'
        },
        {
          id: 'pa1hotFill',
          name: 'PA-1 HOT FILL',
          overlayText: 'The PA-1 ECO machine is equipped with an additional package that allows the production of hot-fillable bottles. The machine can easily switch between HOTFILL or conventional bottle production.'
        },
        {
          id: 'pe15uni',
          name: 'PE-15 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pe110uni',
          name: 'PE-110 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. The machine is specially developed for the production of larger-volume bottles.'
        },
        {
          id: 'pap2',
          name: 'PAP-2',
          overlayText: 'The PA machine family, except for the stretch operated by a servomotor, consists of machines operated by pneumatic cylinders. The PAP machines are specifically designed for producing small-volume bottles with minimal space and energy requirements. This type is fully automatic. The machine’s control is capable of serving a filling machine in online mode, placing the finished bottles on a conveyor belt with an additional unit. The machine is the same size as the PAP-1 but can produce two smaller bottles at once.'
        },
        {
          id: 'pa2ecoplus',
          name: 'PA-2 ECO+',
          overlayText: 'The PA machine family, except for the stretch operated by a servomotor, consists of machines operated by pneumatic cylinders. This type is fully automatic. The machine’s control is capable of serving a filling machine in online mode. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. The ECO+ version is the modernized, energy-efficient model of the pneumatically operated machines.'
        },
        {
          id: 'pe2uni',
          name: 'PE-2 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pe2uniHotFill',
          name: 'PE-2 UNI HOT FILL',
          overlayText: 'The PE-2 machine is equipped with an additional package that allows the production of hot-fillable bottles. The machine can easily switch between HOTFILL or conventional bottle production.'
        },
        {
          id: 'pe25uni',
          name: 'PE-25 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pa210uni',
          name: 'PA-210 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pe4uni',
          name: 'PE-4 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pe6uni',
          name: 'PE-6 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE family is characterized by high energy efficiency and minimized air consumption due to various air recovery systems. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines. Quick product and preform changes are supported by many integrated and optional solutions.'
        },
        {
          id: 'pe12uni',
          name: 'PE-12 UNI',
          overlayText: 'All the main movements of the PE machine family are operated by servomotors, allowing fast movement speeds that shorten the cycle time and increase productivity. The machine family is built using the most modern electronic and data transmission components, allowing for very precise movement and technology settings with controlled feedback. The PE-12 model is specifically developed for producing small-volume bottles. Bottles exit the machine through air conveyors, and the control is suitable for online operation with filling machines.'
        }
      
      ],      
    //#endregion
    //PRODUCT PAGE
    //#region
    pp1: 'Properties',
    pp2: 'Get a quote',
    pp3: 'Recommended usage',
    pp4: 'Gallery',
    //#endregion
    //IMG ALTS
    //#region
    imgAlt0:'Poliol Logo',
    imgAlt1:'Markoló üríti rakományát.',
    imgAlt2:'Flakonfúvó gép váz rajz',
    imgAlt3:'Víz fröcsköl egy vizesflakonból',
    imgAlt4:'Világtérkép',
    imgAlt5:'Markoló kanállal',
    imgAlt6:'Hegesztés',
    imgAlt7:'Gyártott bányakanál',
    imgAlt8:'Robothegesztés',
    imgAlt9:'Fehér flakonok sorakoznak',
    imgAlt10:'Alakadó szerszám gyártása',
    imgAlt11:'Flakon tervezése',
    imgAlt12:'Futurisztikus vizes flakon terv koncepció',
    imgAlt13:'',
    imgAlt14:'',
    imgAlt15:'',
    imgAlt16:'',
    imgAlt17:'',
    imgAlt18:'',
    imgAlt19:'',
    imgAlt20:'',
    imgAlt21:'',
    imgAlt22:'',
    //#endregion
    //PRIVACY POLICY, DATA PROTECTION
    //#region
    dp1:'Privacy Policy',
    dp2:'Privacy Policy, Data protection, GDPR document',
    cp1:'Complaint Policy',
//#endregion
      //TENDERS
    //#region
    t1: 'Current tenders and projects',
    t2: 'Project identification number:',
    t3: 'GINOP_PLUSZ-1.2.3-21-2022-01788',
    t4: 'Beneficiary:',
    t5: 'Poliol Ltd.',
    t6: 'Project title:',
    t7: 'Complex development implementation at Poliol Ltd.',
    t8: 'Successfully implemented tenders',
    t9: 'Read more',
    t10: 'Grant amount:',
    t11: 'Grant intensity:',
    t12: 'Purpose of development:',
    t13: 'Planned completion date of the project:',
    t14: '',
    tenderInfo:[
      {id: 'GINOP-3.2.2-8-2-4-16-2020-02008', p1: '22,269,208 HUF', p2: '55%', p3: 'In addition to continuous capacity expansion, it is important to monitor most of our processes, measure the efficiency of our material and human capacities, and therefore, we plan to replace our outdated ERP system with the GDSERP BEST PRO Integrated Management System. As part of the new hybrid ERP, we will purchase 11 modules, and the off-the-shelf product will be customized to meet our needs. Purchasing Solid Edge 3D CAD software became necessary to speed up the increasing design tasks.', p4: 'The project will strengthen and expand our computer systems (server, designer workstations with monitors, routers improving wi-fi security and performance, uninterruptible power supplies, external drives, laptops, and Zebra scanner and barcode printer equipment).', p5: '2022.03.31.'},
      {id: 'GINOP_PLUSZ-1.2.3-21-2022-01788', p1: '144,200,000 HUF conditionally repayable support', p2: '70%', p3: 'The project involves planning a metalworking hall where material handling/lifting tasks will be carried out by a 10t double-girder bridge crane. To provide modern working conditions, an extremely modern, energy-efficient air-to-water heat pump-based cooling-heating air conditioning unit will also be installed.\n\nWe also plan to purchase a flatbed plasma cutter with a 2200x6400mm table size (cutting capacity), which is capable of cutting thick plates (over 15mm).', p4: 'Employee competence development is planned within the framework of organizational development training. Additionally, to determine the milestones and recommendations for the production management system, we will entrust an IT company with over 25 years of experience to provide consulting services.', p5: '2024.02.15.'},
      {id: 'GINOP-1.2.10-19-2019-00135', p1: '39,894,000 HUF', p2: '70%', p3: 'With over 17 years of experience in bottle blowing machine manufacturing and 28 years in bottle production, we know that continuous product innovation is essential based on customer feedback and market trends. One innovative direction of our current PE UNI machine series development is a faster machine for more uniform bottle wall thickness, which allows for better quality bottle production with less material usage, resulting in cost-effective manufacturing.\nTo automate the welding of the complex geometry frames of our blow molding machines, we emphasize the thickness and quality of the frame material and welding. We have custom-designed a semi-automatic welding robot with 3t capacity.', p4: 'The custom-made robot arm has 6-axis motion, and the length of the path is also unique. The fixtures for our custom-made parts are designed for quick and precise replacement. Semi-automatic operation allows for manual control when performing more complex tasks.', p5: '2020.10.31.'},
      {id: 'GINOP-2.1.8-17-2018-03529', p1: '28,902,457 HUF', p2: '50%', p3: 'With more than 17 years of experience in bottle blowing machine manufacturing and 28 years in bottle production, we know that continuous product innovation is essential. Our PE UNI machine family’s innovative direction is to develop a faster machine with uniform wall thickness for better quality bottles. Until now, we painted the frames and outer casings of the machines outside the workshop. With the purchase of the VANEX SPR-103 paint-drying cabin, we can perform this work in-house.', p4: 'The innovative LORCH S5 Speed Pulse XT welder provides strong and aesthetic welds. Its high-speed operation stresses the welded parts and seams more, so it is necessary to ensure proper welding equipment.', p5: '2019.10.15.'},
      {id: 'GINOP-1.3.1-15-2016-00339', p1: '17,520,934 HUF', p2: '50%', p3: 'Thanks to the project, our company can focus more on Western European markets, primarily Germany. The project includes participation in two exhibitions in Germany.', p4: 'Complex capacity expansion in production\nCapacity-expanding investment\nSite and technology development\nComplex corporate technology development\nTechnological development\nSite development\nCorporate innovation support\nUtilization of innovation results', p5: '2017.11.30.'}
    ],
    //#endregion

            //rPet MODAL
    //#region
    rpet1: 'We kindly inform our valued customers to place their future material orders considering the legal regulations regarding rPET bottles, which will come into effect from 2025.01.01.',
    rpet2: 'Reference to the legislation',
    rpet3: 'Directive of the European Parliament and Council on reducing the environmental impact of certain plastic products',
    rpet4: 'Compliance with the requirements set forth in the legislation is the responsibility of the distributor, not POLIOL Ltd., as the manufacturer. Therefore, our company will continue to produce virgin PET bottles upon request.',
    rpet5: 'Thank you,',  
    rpet6: 'available here',
    rpet7: 'can be found here',
    rpet8: '',
    rpet9: '',
  
    //#endregion
  }