export default {
    //REGULAR EXPRESSIONS
    //#region
    re1: 'Érdeklődöm',
    ty:'Köszönjük!',
    //#endregion
    //SITES
    //#region
    sites: [
      {text: 'Főoldal', url: '', metaName:'',metaContent:''},
      {text: 'Flakongyártás', url: 'flakongyartas',metaName:'',metaContent:''},
      {text: 'Kanálgyártás', url: 'kanalgyartas',metaName:'',metaContent:''},
      {text: 'Flakonfúvó gépgyártás', url: 'flakonfuvo-gepgyartas',metaName:'',metaContent:''},
      {text: 'Szerelékek', url: 'szerelekek',show: false, metaName:'',metaContent:''},
      {text: 'Adatvédelem', url: 'adatvedelem', right:true, metaName:'',metaContent:''},
      {text: 'Panaszkezelés', url: 'panaszkezeles', right:true, metaName:'',metaContent:''},
      {text: 'Pályázatok', url: 'palyazatok', right:true, metaName:'',metaContent:''},
    ],
    //#endregion
    //MAIN VIEW
    //#region
    h1: 'Harminc év, ',
    h2: 'a tapasztalat',
    h3: ' garanciája',
    h4: 'Korszerű technológiai megoldásaink a vevőink kényelmét, produktivitását, valamint a költséghatékonyság növelését szolgálják hosszú éveken keresztül, versenyképes áron.',
    h5: 'Beüzemelt gép',
    h6: 'Év Tapasztalat',
    h7: 'Termékkategória',
    h8: 'Munkatárs',
    hb1: 'Flakonfúvó gépgyártás',
    hb2: 'Magyarország legnagyobb Flakonfúvó Gép gyártója',
    hb3: 'Flakongyártás',
    hb4: 'Az egyedi tervezésű PET flakonok szakértője',
    hb5: 'Szerelékgyártás',
    hb6: 'A professzionális kanalak nálunk készülnek',
    hb7: 'Olvass tovább →',
    h9: 'Kérjen ajánlatot!',
    au1: 'Rólunk',
    au2: 'iparágakon átívelő megoldások',
    au3: 'Átfogó szakmai tapasztalat a műanyag csomagolóipar, a gépgyártás, fémmegmunkálás, valamint a földmunkagép szerelékgyártás terén',
    au4: 'Fejlesztési és innovációs erőnkre, motivált munkatársaink teljesítményére, valamint elkötelezett és elismert szakértőink tudására támaszkodva fejlesztjük termékeinket, technológiánkat, annak érdekében, hogy hozzájárulhassunk vevőink fejlődéséhez és a közös sikerünkhöz.',
    at: 'Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló .',
    h10: 'poliol szerelékgyártás',
    wfd1: 'Merjen velünk',
    rmb1: 'Több információ',
    rmb2: 'Érdeklődöm',
    wfd2: 'Széria és egyedi gyártás',
    wfd3: 'Azonnal elérhető katalógus termékek raktárról. Egyedi megoldások egyedi vevői igényekre, alacsony átfutási idővel.\'',
    wfd4: 'Poliol Service Assistance ™',
    wfd5: 'Folyamatos rendelkezésreállás, 24-48 órás kiszállás. Csere kanál, szerelék biztosítása a javítás időszakára.',
    wfd6: 'A részletekben is precíz',
    wfd7: 'Saját fejlesztésű és gyártású munkahenger, valamint gyorscserélő rendszerek.',
    wfd8: 'Részletek',
    h11: 'poliol flakonfúvó gépgyártás',
    bmwfd1: 'Gépgyártás',
    bmwfd2: 'vevőközpontú innováció',
    bmwfd3: 'A több mint 20 év tisztán magyar know-how tudásra és gyártási tapasztalatra épülő POLIOL flakonfúvó gépek tervezésnél és gyártásánál fő szempontunk, hogy olyan gépeket építsünk, amelyek időtálló értéket és stabil gyártó kapacitást biztosítanak a vevőinknek.',
    ml1: 'Helyszíneink',
    ml2: 'Helyszínen, a világ 8 országában POLIOL gyártmányú flakonfúvó gépek üzemelnek, maximális vevői elégedettség mellett.',
    ml3: 'Románia',
    ml4: 'Algéria',
    ml5: 'Magyarország',
    ml6: 'Belgium',
    ml7: 'Horvátország',
    ml8: 'Észak Macedónia',
    ml9: 'Moldávia',
    ml10: 'Szerbia',
    pmp1: 'Magyarország legnagyobb flakonfúvógép gyártója',
    pmp2: 'Megbízható működés',
    pmp3: 'A legmagasabb minőségű elektronikai és pneumatikai alkatrészek használatával',
    pmp4: 'Hidraulikus előforma felöntő',
    pmp5: 'a teljeskörű automatizáltság támogatására',
    pmp6: 'Erős váz',
    pmp7: 'Gépeink kiemelkedő stabilitása az erős váz felépítésével kezdődik',
    pmp8: 'Kedvező üzemeltetési költség',
    pmp9: 'Az alacsony szervizigénynek, valamint az energiahatékony megoldásoknak köszönhetően',
    h12: 'Különleges ajánlat! Szerezze be gépeinket most készletről!',
    h13: 'Miért válassza most?',
    h14: 'Azonnal elérhető: Nincs várakozási idő, rövid időn belül használatba veheti új gépét.',
    h15: 'Megbízhatóság és minőség: Gépeink évek óta bizonyítják kiválóságukat, legyen szó bármilyen iparágról.',
    h16: 'Kedvező árak: Különleges áron kínáljuk őket, így Ön is részesülhet a legjobb technológiában kedvező feltételekkel.',
    h17: 'Ne hagyja ki ezt a lehetőséget! Gépeink most még elérhetők készletről.',
    h18: 'poliol flakongyártás',
    bpm0: 'Flakongyártás',
    bpm1: '10 milliárd flakon tapasztalat',
    bpm2: 'tapasztalat',
    bpm3: 'Ötlettől a kész flakonig',
    bpm4: 'Egyedi design tervezés - 3D modellezés - gyártás - cimkézés, szitázás házon belül.',
    bpm5: 'Részletek',
    bpm6: 'Válasszon online',
    bpm7: 'Webshop termékek azonnali elérhetőséggel. Egyedi megoldások egyedi vevői igényekre, alacsony átfutási idővel.',
    bpm8: 'Flakon Webshop',
    bpm9: 'minden méretben',
    bpm10: 'Az igény alapján tervezünk és gyártunk különböző méretű, formájú - 40 ml térfogatú flakonoktól a 12 l térfogatú flakonokig, hengeres vagy négyzet alakú, de akár egyedi formavilággal bíró termékeket',
    bpm11: 'Részletek',
    cup1: 'kapcsolat',
    cup2: 'Keressen minket!',
    cup3: 'Forduljon hozzánk bizalommal',
    cup4: 'üzenet',
    cup5: 'írjon nekünk:',
    cup6: 'Név*',
    cup7: 'Telefon*',
    cup8: 'Üzenet (opcionális)',
    cup9: 'Üzenet küldése',
    cup10: 'Nem adott meg nevet',
    cup11: 'Nem adott meg e-mail címet',
    cup12: 'Nem adott meg telefonszámot',
    cup13: 'Általános adatkezelési tájékoztató',
    cup14: 'Az általános adatkezelési tájékoztatót megismertem',
    cup15: 'El kell fogadnia az általános adatkezelést',
    fc1: 'Segítünk a szintlépésben',
    fc2: 'Szerelék-, kanálgyártás',
    fc3: 'Tősi János',
    fc4: 'Flakonfúvó gépgyártás',
    fc5: 'Lukács Csaba',
    fc6: 'Flakongyártás',
    fc7: 'Bíró Adrienn',
    fc8: 'központ',
    fc9: 'Minden jog fenntartva © Poliol Kft. - 2024',
    fc10: 'cím',
    fc11: 'adatkezelési tájékoztató',
    //#endregion

    //BOTTLE BLOWING SITE
    //#region
    //BOTTLE BLOWING REGULAR
    bb1: 'Iparágak ahol flakonjaink már teljesítettek',
    //BOTTLE BLOWING BANNER
    bbb1: 'HOTFILL technológia',
    bbb2: 'Tartsa meg versenyelőnyét forradalmi HOTFILL technológiánkkal. Maximális hőállóság és biztonság. Tudjon meg többet a legmodernebb palackgyártási módszereinkről!',
    bbb3: 'Tartsa meg versenyelőnyét forradalmi HOTFILL technológiánkkal. Maximális hőállóság és biztonság.',
    bbb4: 'Környezettudatosság',
    bbb5: 'Fenntartható PET palackgyártás környezetbarát megoldásokkal. Fedezze fel, hogyan óvjuk a bolygót termékeinkkel!',
    bbb6: 'Egyedi tervezés',
    bbb7: 'Ötlettől a kész flakonig, a teljes palackgyártási folyamatot házon belül végezzük. Az egyedi tervezésű koncepcióktól a termék előállításáig csapatunk mindent egy helyen kezel. Hatékonyan és eredményesen alakítjuk át terveit kiváló minőségű PET palackokká.',
    bbb8: 'Ötlettől a kész flakonig, a teljes palackgyártási folyamatot házon belül végezzük. Az egyedi tervezésű koncepcióktól a termék előállításáig csapatunk mindent egy helyen kezel.',
    bbb9: 'Címkézés, szitázás',
    bbb10: 'Növelje a PET palackok vonzerejét címkézési és szitázási szolgáltatásainkkal! Kiváló minőségű, egyedileg testreszabott lehetőségek, melyek segítségével termékei kitűnnek majd a polcokon!',
    bbb11: 'Növelje a PET palackok vonzerejét címkézési és szitázási szolgáltatásainkkal!',
    //BOTLLE BLOWING PRODUCT CATEGORIES
    bbpc1: 'Vegyipar',
    bbpc2: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására.',
    bbpc3: 'Élelmiszeripar',
    bbpc4: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét.',
    bbpc5: 'Ásványi olaj, kenőanyag',
    bbpc6: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat.',
    bbpc7: 'Kozmetikai',
    bbpc8: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét.',
    //BOTTLE BLOWING TITLE TEXT IMAGE
    bbtti1: 'miért a POLIOL flakonok',
    bbtti2: 'ÖN ELKÉPZELI, MI MEGVALÓSÍTJUK',
    bbtti3: 'A kiváló minőségben gyártott, saját tervezésű és fejlesztésű műanyag alapanyagú flakonjaink rendszeresen bizonyítottak az elmúlt években.',
    bbtti4: 'Számtalan kis-, és középvállalat egyedi igénye alapján tervezünk és gyártunk különböző méretű, formájú - 40 ml térfogatú flakonoktól a 12 l térfogatú flakonokig, hengeres vagy négyzet alakú, de akár egyedi formavilággal bíró - flakonokat, amelyek főként különböző folyadékok – ital, élelmiszer és vegyianyag - tárolására alkalmasak. Egyik legnagyobb sikerünk, hogy a saját tapasztalatunk és tudásunk alapján megtervezett és mai használatban lévő Szentkirályi Ásványvíz 5 literes flakonunk NÍVÓDÍJAT kapott.',
    //BOTTLE BLOWING PRODUCT HEROES
    bbpc11: 'HOTFILL',
    bbpc12: 'Frissesség minden cseppben',
    bbpc13: 'Élvezze új Hotfill termékeink előnyeit, melyek lehetővé teszik a magas hőmérsékletű folyadék töltést a friss ízek megőrzéséért és a hosszabb eltarthatóságért. Tapasztalja meg, hogyan emeljük új szintre a PET csomagolástechnikát. Legyen részese a Hotfill forradalmának!',
    bbpc14: ['Gyümölcslé', 'Tea', 'Energiaital', 'Méz', 'Smoothie'],
    bbpc21: 'Élelmiszer',
    bbpc22: 'A higiénikus tárolás szolgálatában 30 éve',
    bbpc23: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét. Fedezze fel megoldásainkat a biztonságos tárolás érdekében!',
    bbpc24: ['Víz', 'Étolaj', 'Tej', 'Méz', 'Szörp'],
    bbpc31: 'Szeszipar',
    bbpc32: 'Minőség a palackban is',
    bbpc33: 'Kiváló minőségű PET palackok a szeszes italokhoz, melyek megőrzik az ízeket és frissességet. Válassza a legjobb megoldást, hogy prémium minőségben szolgálhassa ki ügyfeleit!',
    bbpc34: ['Sör', 'Bor', 'Pálinka', 'Cider'],
    bbpc41: 'Kozmetika',
    bbpc42: 'Elegancia és tartósság egy palackban',
    bbpc43: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét. Tegye vonzóvá márkáját kiváló csomagolási megoldásainkkal!',
    bbpc44: ['Krémek', 'Testápolók','Tusfürdők', 'Samponok'],
    bbpc51: 'Ásványi olaj, kenőanyag',
    bbpc52: 'Biztonságos tárolás és szállítás',
    bbpc53: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat. Bízza ránk a csomagolást, hogy termékei minden körülmények között tökéletes állapotban maradjanak!',
    bbpc54: ['Motorolaj', 'Fékfolyadék', 'Kenőanyagok', 'Hűtőfolyadék'],
    bbpc61: 'Vegyszer, tisztítószer',
    bbpc62: 'Biztonság és megbízhatóság',
    bbpc63: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására. Válassza a megbízhatóságot és a minőséget termékeinkkel!',
    bbpc64: ['Tisztítószerek', 'Fertőtlenítők', 'Mosószerek', 'Ipari vegyszerek'],

    //#endregion
    //BUCKET PRODUCTION SITE
    //#region
    //BUCKET PRODUCTION REGULAR
    bp1: 'Szerelékeink',
    //BUCKET PRODUCTION BANNER
    bpb1: 'Nagy teherbírású bányakanál',
    bpb2: 'Innovatív megoldások, kivételes szilárdságú és tartósságú bányakanalak!',
    bpb3: '7 m³ magasürítésű kanál',
    bpb4: 'Egyedi megoldásokat tervezünk és gyártunk, mindig az ügyfelek specifikus igényei szerint!',
    bpb5: 'Terméklap',
    //BUCKET PRODUCTION CATEGORIES
    bpc1: 'Faipar, Mezőgazdaság',
    bpc2: 'Tartós és hatékony kanalak és szerelékek a faipar és mezőgazdaság számára. 30 év tapasztalat, nagy teljesítményű tervező és gyártó sor!',
    bpc3: 'Nagy tömegű földmunka',
    bpc4: 'Erős kanalak és szerelékek a nagy tömegű földmunkákhoz. Növelje hatékonyságát és minimalizálja a leállási időt!',
    bpc5: 'Közműépítés',
    bpc6: 'Megbízható eszközök a közműépítéshez. Rendeljen még ma, és használja ki gyors kiszállási szolgáltatásunkat!',
    bpc7: 'Városfenntartás',
    bpc8: 'Professzionális kanalak és szerelékek a hatékony városfenntartásért. Megbízhatóságot tervezünk, gyártunk és kínálunk!',
    bpc9: 'Bányászat',
    bpc10: 'Tartós Hardox anyagból készült eszközök a kő- és sziklabányászat igényeihez. Fedezze fel termékeinket, melyek ellenállnak még a legkeményebb körülményeknek is!',
    bpc11: 'Gyors szerelékváltók',
    bpc12: 'Gyors és megbízható szerelékváltók a maximális hatékonyságért. Válasszon innovatív megoldásaink közül, vagy tervezzünk teljesen egyedit együtt!',
    //BUCKET PRODUCTION TITLE TEXT IMAGE
    bptti1: 'miért POLIOL szerelék',
    bptti2: 'Merjen velünk!',
    bptti3: 'A Poliol több mint 30 éves tapasztalattal rendelkezik a kanalak és szerelékek gyártásában. Cégünk innovatív megoldásokat kínál, amelyek garantálják a tartósságot és a megbízhatóságot az ipar minden területén.',
    bptti4: 'Saját fejlesztésű és gyártású munkahengereket és gyorscserélő rendszereket kínálunk. Ezek az innovációk megbízhatóságot és hatékonyságot biztosítanak a legkülönfélébb alkalmazásokhoz. Csapatunk professzionális eszköztárral és tudással rendelkezik a kanalak és szerelékek felújításában és modernizálásában is, ezzel biztosítva a legkülönbözőbb iparágakban is a berendezések tartósságát és hatékonyságát. Termékeink Hardox anyagokból készülnek, melyek kivételes szilárdságukról és tartósságukról ismertek, biztosítva a hosszú távú teljesítményt. De nemcsak a megbízhatóság jellemző ránk, hanem a gyorsaság is: katalógus termékeinket, akár 2 hetes átfutási idővel szállítjuk!',
    //#endregion
    //BOTTLE BLOWING MACHINE PRODUCTION
    //#region
    //BOTTLE BLOWING MACHINE PRODUCTION REGULAR
    bbmp1: 'Flakonfúvógép típusaink',
    bbmp2: 'Flakonfúvó gépeink',
    bbmp3: 'További gépeink',
    bbmp4: 'Termékek elrejtése',
    bbmProdProperties: ['Fészekszám', 'Maximális térfogat', 'Maximális gyártási kapacitás'],
    //BOTTLE BLOWING MACHINE PRODUCTION BANNER
    bbmpb1: 'PA-2 ECO+',
    bbmpb2: 'A félautomata gépek kiváltásának leghatékonyabb eszköze.',
    bbmpb3: 'PE-UNI',
    bbmpb4: '30 év fejlesztés és megbízhatóság új köntösben.',
    //BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
    bbmpc1: 'Tejüzemek',
    bbmpc2: 'Gépeinkkel prémium minőségű PET palackokat gyárthat, melyek biztosítják a tejtermékek biztonságos és hosszú távú tárolását.',
    bbmpc3: 'Sörfőzdék, borászatok',
    bbmpc4: 'Innováció mindfelett: gyártson innovatív, trendi és fancy palackokat házon belül! Termékminőség és tartósság!',
    bbmpc5: 'Üdítőgyártás',
    bbmpc6: 'Tartós és esztétikus PET palackokat gyárthat üdítőgyártóként. Biztosítsa gépeinkkel a termékek vonzerejét és biztonságát, házon belül!',
    bbmpc7: 'Kozmetikai cégek',
    bbmpc8: 'Növelje márkája vonzerejét tartós és elegáns PET palackokkal, melyek biztosítják a termékek biztonságát és frissességét. Gyártson PET palackokat házon belül gépeinkkel!',
    bbmpc9: 'Olajüzemek',
    bbmpc10: 'Ellenálló, minőségi palackok a legszigorúbb ipari követelményeknek megfelelően. Biztosítsa a termékek épségét, házon belül!',
    //BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
    bbmptti1: 'miért a Poliol?',
    bbmptti2: 'A legtestreszabhatóbb flakonfúvógépek a világon',
    bbmptti3: 'A Poliol flakonfúvógépei a testreszabhatóság és megbízhatóság csúcsát képviselik. Több mint 30 év tervezési és gyártási tapasztalatával biztosítjuk, hogy gépeink megfeleljenek a legszigorúbb ipari követelményeknek.',
    bbmptti4: 'Innovatív megoldásaink révén gépeink nemcsak energiatakarékosak és helytakarékosak, hanem gyors átállási lehetőségeket is kínálnak, maximalizálva az üzemeltetési hatékonyságot. A szervómotoros működtetés és hidraulikus előforma feladó rendszer garantálja a precíz és megbízható gyártást. Már több mint három évtizede specializálódtunk a lineáris palackfúvó gépekre, páratlan szakértelmet és megbízhatóságot kínálva a piacon. Gépeinket stabil, masszív kialakítás, energiahatékonyság és helytakarékosság jellemzi, biztosítva, hogy zökkenőmentesen illeszkedjenek gyártási környezetébe. Válassza a Poliol flakonfúvógépeit, és élvezze a teljes mértékben testreszabható és kiváló minőségű megoldásokat!',
    //BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
    bbmppc11: '30 éves minőség, új köntösben.',
    bbmppc12: 'Az univerzálisabb felhasználást nyújtó UNI gépcsalád a teljesen automata működésével emelkedik ki a terméklistából. Gyors átállást, erősebb fúvónyomást és nagyobb űrtartalmú flakonokat garantál vásárlóinknak.',
    bbmppc13: 'POLIOL Finance™   Bérleti lehetőségek: Rugalmas bérleti lehetőségek az Ön pénzügyi igényeinek megfelelően.',
    bbmppc14: ['0.2 l - 19 l gyártható űrtartalom', 'szerszám nélküli átállás 10 perc alatt', 'HOTFILL opció', 'Akár 40 Bar fúvónyomás a legnagyobb kihívásokra'],
    bbmppc21: 'A félautomata gépek kiváltásának leghatékonyabb eszköze.',
    bbmppc22: 'Az economy sorozatú flakonfúvó gépeink tervezésénél elsősorban az energiatakarékosság, valamint a fenntarthatóság voltak a legfőbb szempontok, a gyártásunkra jellemző maximális minőségi szint és megbízható működés megtartása mellett.',
    bbmppc23: 'POLIOL Finance™   Bérleti lehetőségek: Rugalmas bérleti lehetőségek az Ön pénzügyi igényeinek megfelelően.',
    bbmppc24: ['Teljesen automata működés', 'Alacsony energiafogyasztás', 'Levegővisszanyerő rendszer', 'Kompakt kivitel', 'Gyors, könnyű telepítés'],
    bph: ' db/óra',
    //#endregion

    //PRODUCT PAGE
    //#region
    pp1: 'Tulajdonságok',
    pp2: 'Ajánlatkérés',
    pp3: 'Javasolt felhasználás',
    pp4: 'Galéria',
    //#endregion

    //PRODUCT GROUPS
    //#region
    prodGroupLocale:
        [
          {
            id: 'buckets',
            name: 'Szerelékek',
            types: [
              {
                id: 'faipar',
                name: 'Faipar, Mezőgazdaság',
                subtitle: 'Tartós és hatékony kanalak és szerelékek a faipar és mezőgazdaság számára. 30 év tapasztalat, nagy teljesítményű tervező és gyártó sor!',
              },
              {
                id: 'foldmunka',
                name: 'Nagy tömegű földmunka',
                subtitle: 'Erős kanalak és szerelékek a nagy tömegű földmunkákhoz. Növelje hatékonyságát és minimalizálja a leállási időt!',
              },
              {
                id: 'kozmu',
                name: 'Közműépítés',
                subtitle: 'Megbízható eszközök a közműépítéshez. Rendeljen még ma, és használja ki gyors kiszállási szolgáltatásunkat!',
              },
              {
                id: 'varos',
                name: 'Városfenntartás',
                subtitle: 'Professzionális kanalak és szerelékek a hatékony városfenntartásért. Megbízhatóságot tervezünk, gyártunk és kínálunk!',
              },
              {
                id: 'banyaszat',
                name: 'Bányászat',
                subtitle: 'Tartós Hardox anyagból készült eszközök a kő- és sziklabányászat igényeihez. Fedezze fel termékeinket, melyek ellenállnak még a legkeményebb körülményeknek is!',
              },
              {
                id: 'gyorscserelok',
                name: 'Gyors szerelékváltók',
                subtitle: 'Gyors és megbízható szerelékváltók a maximális hatékonyságért. Válasszon innovatív megoldásaink közül, vagy tervezzünk teljesen egyedit együtt!',
              },
            ]
          },
          {
            id: 'bottles',
            name: 'Flakonok',
            types: [
              {
                id: 'vegyipar',
                image: 'vegyipar.webp',
                name: 'Vegyipar',
                subtitle: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására.',
              },
              {
                id: 'elelmiszeripar',
                image: 'Uditogyartas.webp',
                name: 'Élelmiszeripar',
                subtitle: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét.',
              },
              {
                id: 'asvanyi-olaj-kenoanyag',
                image: 'Olajuzemek.webp',
                name: 'Ásványi olaj, kenőanyag',
                subtitle: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat.',
              },
              {
                id: 'kozmetikai',
                image: 'Kozmetikai-cegek.webp',
                name: 'Kozmetikai',
                subtitle: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét.',
              },
            ]
          },
        ],
    //#endregion


    //PRODUCTS
    //#region
    prodLocale: [
      {
        id: 'rezsu-kanal',
        name: 'Rézsűkanál',
        properties: [
          'HARDOX 500-as vágóél',
          'Erős, egyedi kivitelű kanálprofil',
          'Többféle profillal is elérhető',
        ],
        usage: 'Rézsű szedésre medrek árkok tisztítására, tükör szedésre ajánljuk.',
        text: 'A fix rézsűkanál tökéletes megoldás a földmunkákhoz, különösen árkok, lejtők és rézsűk kialakításához. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Segítségével precízebb és hatékonyabb munkavégzés érhető el. Válassza a fix rézsűkanalat a pontos és megbízható földmunkákért!',
      },
      {
        id: 'hidraulikus-rezsu-kanal',
        name: 'Hidraulikusan billenthető rézsű kanál',
        properties: [
          'HARDOX 500-as vágóél és oldalél',
          'Masszív, S355-ös acél kanáltest',
          'Több merevítő sarló',
          'Modern kanálprofil',
          'Hátrafelé túlnyúló símító felület többletköltség nélkül',
          'Precíz munkavégzés állítható átfolyási sebeséggel a munkahengerek védelme és a precíz munkavégzés érdekében'
        ],
        usage: 'Minden olyan tükör és rézsű szedési feladathoz ajánjuk ahol a talaj egyenletlenség kiküszöbölése nem megoldható a gép számára.',
        text: 'A hidraulikus rézsűkanál forradalmasítja a rézsűk és árkok formálását, lehetővé téve a precíz és hatékony munkavégzést bármilyen munkaterületen. Az innovatív hidraulikus rendszer lehetővé teszi a könnyű és pontos manőverezést, valamint a magas teljesítményű földmunkákat a gépvezető számára. A magas minőségű anyagokból készült kanál kiemelkedő kopásállóságot és hosszú élettartamot garantál a hosszú távú, megbízható használat érdekében.\n' +
            'Válassza a hidraulikus kivitelt a széleskörű alkalmazhatóság és a hatékony földmunkák rugalmasságáért! Bízza rá a legprecízebb munkafolyamatokat egy olyan eszközre, amely a teljesítmény és a megbízhatóság csúcsát képviseli minden munkaterületen!'
      },
      {
        id: 'melyaso-kanal-korom-nelkul',
        name: 'Mélyásó kanál vágóéllel',
        properties: [
          'Gépkezelői visszajelzések alapján kialakított kanálprofil',
          'HARDOX 500-as vágóél és oldalél',
          'Standard, erősített, és extra erősített kivitelben is',
          'Minden típusú kotróra'
        ],
        usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
        text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
      },
      {
        id: 'rezsu-kanal-varos',
        name: 'Rézsűkanál',
        properties: [
          'HARDOX 500-as vágóél',
          'Erős, egyedi kivitelű kanálprofil',
          'Többféle profillal is elérhető',
        ],
        usage: 'Rézsű szedésre medrek árkok tisztítására, tükör szedésre ajánljuk.',
        text: 'A fix rézsűkanál tökéletes megoldás a földmunkákhoz, különösen árkok, lejtők és rézsűk kialakításához. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Segítségével precízebb és hatékonyabb munkavégzés érhető el. Válassza a fix rézsűkanalat a pontos és megbízható földmunkákért!',
      },
      {
        id: 'melyaso-kanal',
        name: 'Mélyásó kanál körömmel',
        properties: [
          'Gépkezelői visszajelzések alapján kialakított kanálprofil',
          'HARDOX 500-as vágóél és oldalél',
          'Választható körömrendszerrel',
          'Kopóccsíkokkal szerelve a palásst lemezen',
          'Standard, erősített, és extra erősített kivitelben is',
          'Minden típusú kotróra'
        ],
        usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
        text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
      },
      {
        id: 'trapez-kanal-foldmunka',
        name: 'Trapéz kanál',
        properties: [
          '500 HB vágóél',
          'Hidraulikusan dönthető és állítható oldaléllel is rendelhető',
          'Gépkezelői visszajelzések alapján kialakított profil',
        ],
        usage: 'Árkok ásásához ajánljuk - utómunkát nem igénylő szimmetrikus és aszimmetrikus árokprofilokhoz',
        text: 'Fedezze fel a trapéz kanál sokoldalúságát, amely ideális megoldást kínál árkok ásásához, alakításához és tisztántartásához, valamint csatornák karbantartásához! Magas minőségű anyagból készült és kiemelkedő kopásállósággal rendelkezik, garantálva a hosszú élettartamot és a megbízhatóságot a munkafolyamat során. Emelje munkái színvonalát a trapéz kanál segítségével, amely precízebb és hatékonyabb eredmények elérését teszi lehetővé minden körülmények közt. A legjobb választás, amikor a minőség és a teljesítmény számít!'
      },
      {
        id: 'melyaso-kanal-korom-nelkul-kozmu',
        name: 'Mélyásó kanál vágóéllel',
        properties: [
          'Gépkezelői visszajelzések alapján kialakított kanálprofil',
          'HARDOX 500-as vágóél és oldalél',
          'Standard, erősített, és extra erősített kivitelben is',
          'Minden típusú kotróra'
        ],
        usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
        text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
      },
      {
        id: 'trapez-kanal-kozmuepites',
        name: 'Trapéz kanál',
        properties: [
          '500 HB vágóél',
          'Hidraulikusan dönthető és állítható oldaléllel is rendelhető',
          'Gépkezelői visszajelzések alapján kialakított profil',
        ],
        usage: 'Árkok ásásához ajánljuk - utómunkát nem igénylő szimmetrikus és aszimmetrikus árokprofilokhoz',
        text: 'Fedezze fel a trapéz kanál sokoldalúságát, amely ideális megoldást kínál árkok ásásához, alakításához és tisztántartásához, valamint csatornák karbantartásához! Magas minőségű anyagból készült és kiemelkedő kopásállósággal rendelkezik, garantálva a hosszú élettartamot és a megbízhatóságot a munkafolyamat során. Emelje munkái színvonalát a trapéz kanál segítségével, amely precízebb és hatékonyabb eredmények elérését teszi lehetővé minden körülmények közt. A legjobb választás, amikor a minőség és a teljesítmény számít!'
      },
      {
        id: 'ripper',
        name: 'Bontófog',
        properties: [
          'Alaptest S355 szerkezeti acélból',
          'HARDOX 500-as burkolat',
          'Könnyen cserélhető körömrendszer',
          'Folyamatosan hegyesen kopó kialakítás',
          'Bármely felfogatással kompatibilis'
        ],
        usage: 'Kemény, szikes talajok fellazításához, tuskózáshoz földbe ágyazódott anyagok kiforgatásához.',
        text: 'Fedezze fel a szakítóköröm sokoldalúságát, ami nemcsak a mini gépeknek, de a legnagyobb lánctalpas kotróknak is fontos eszköze! Ez az innovatív eszköz lehetővé teszi a kemény, szikes talaj felázítását, hogy azt követően könnyedén és hatékonyan vehesse fel a rakodandó anyagokat. Vessen be egy megoldást, amely garantálja a hatékonyságot és eredményességet minden munkaterületen!'
      },
      {
        id: 'hidraulikus-rezsu-kanal-kozmu',
        name: 'Hidraulikusan billenthető rézsű kanál',
        properties: [
          'HARDOX 500-as vágóél és oldalél',
          'Masszív, S355-ös acél kanáltest',
          'Több merevítő sarló',
          'Modern kanálprofil',
          'Hátrafelé túlnyúló símító felület többletköltség nélkül',
          'Precíz munkavégzés állítható átfolyási sebeséggel a munkahengerek védelme és a precíz munkavégzés érdekében'
        ],
        usage: 'Minden olyan tükör és rézsű szedési feladathoz ajánjuk ahol a talaj egyenletlenség kiküszöbölése nem megoldható a gép számára.',
        text: 'A hidraulikus rézsűkanál forradalmasítja a rézsűk és árkok formálását, lehetővé téve a precíz és hatékony munkavégzést bármilyen munkaterületen. Az innovatív hidraulikus rendszer lehetővé teszi a könnyű és pontos manőverezést, valamint a magas teljesítményű földmunkákat a gépvezető számára. A magas minőségű anyagokból készült kanál kiemelkedő kopásállóságot és hosszú élettartamot garantál a hosszú távú, megbízható használat érdekében.\n' +
            'Válassza a hidraulikus kivitelt a széleskörű alkalmazhatóság és a hatékony földmunkák rugalmasságáért! Bízza rá a legprecízebb munkafolyamatokat egy olyan eszközre, amely a teljesítmény és a megbízhatóság csúcsát képviseli minden munkaterületen!'
      },
      {
        id: 'erositett-melyaso-kanal',
        name: 'Bányakanál',
        properties: [
          'Hardox HiTuf 500 kanáltest',
          'Kopásálló speciálís hozaganyaggal készült varratok',
          'A legkeményebb bányászati feladatokra',
          'Egyedi felszereltségi szinttel'
        ],
        usage: 'A legkeményebb bányászati feladatokra és igénybevételekre ajánljuk. Hosszútávú megoldás elérhető áron helyben az országból.',
        text: 'A bányakanál kifejezetten bányászati és nehéz ipari környezetben való felhasználásra készült, nagy mennyiségű anyagok mozgatására. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Hatékonyságával segíti a komolyabb anyagmozgatási feladatok elvégzését. Válassza bányakanalunkat a megbízható és tartós anyagmozgatásért!'
      },
      {
        id: 'erositett-rakodo-kanal',
        name: 'Erősített rakodókanál (HDX)',
        properties: [
          'A legnehezebb bányászati rakodó munkálatokra tervezve',
          'Egyedi méretben minden géptípusra',
          'A kanál minden alkatrésze kopóelemekk és szegmensekkel felszerelhető',
          'Tetszőleges kanálprofil és vágóél forma kiválasztással'
        ],
        usage: 'Bánya kivitel kifejezetten bányászati rakodási feladatokra. Egyedi felszereltséggel amit az ügyfél igényeire szabunk.',
        text: 'Fedezze fel a teljesítmény és a tartósság csúcspontját az erősített rakodókanállal, amelyet kifejezetten a legkeményebb bányászati és ipari kihívásokra terveztünk. A robusztus kialakítás és a túlméretezett alkatrészek még a legszélsőségesebb munkakörülmények között is kiemelkedő teljesítményt nyújtanak. A kanál kopásnak kitett részeit különleges kopásálló lemezekkel védjük, míg az extra megerősített oldalél garantálja a maximális védelmet és tartósságot.'
      },
      {
        id: 'rakodo-kanal',
        name: 'Rakodókanál',
        properties: [
          'Minden típusú rakodógéphez',
          'Akár HARDOX 500 as kanáltesstel',
          'Választható körömszettel mindenféle méretben',
          'Nyitható és magasürítésű kivitelben is',
          'Standard, erősített és bánya kivitelben gyártjuk'
        ],
        usage: 'Elsősorban rakodási feladatokra ajánljuk a kicsitől a nagy tömegig. Minden géptípusra. Anyag milyenségétől függően vágóéles, vagy körmös felszereltséggel.',
        text: 'A rakodókanál sokoldalúan használható anyagok, például homok, kavics és föld rakodására és szállítására. Az egyedi gyártásnak köszönhetően pontosan az Ön igényeire szabott, magas minőségű eszköz. Megbízhatóságával és tartósságával növeli a munkavégzés hatékonyságát. Válassza rakodókanalunkat minden rakodási feladathoz!'
      },
      {
        id: 'ripper-banyaszat',
        name: 'Bontófog',
        properties: [
          'Alaptest S355 szerkezeti acélból',
          'HARDOX 500-as burkolat',
          'Könnyen cserélhető körömrendszer',
          'Folyamatosan hegyesen kopó kialakítás',
          'Bármely felfogatással kompatibilis'
        ],
        usage: 'Kemény, szikes talajok fellazításához, tuskózáshoz földbe ágyazódott anyagok kiforgatásához.',
        text: 'Fedezze fel a szakítóköröm sokoldalúságát, ami nemcsak a mini gépeknek, de a legnagyobb lánctalpas kotróknak is fontos eszköze! Ez az innovatív eszköz lehetővé teszi a kemény, szikes talaj felázítását, hogy azt követően könnyedén és hatékonyan vehesse fel a rakodandó anyagokat. Vessen be egy megoldást, amely garantálja a hatékonyságot és eredményességet minden munkaterületen!'
      },
      {
        id: 'hidraulikus-kotro-XCMG-gyorscserelo',
        name: 'Hidraulikus / Mechanikus Kotró gyorscserélő',
        properties: [
          'A kotrógép sokoldaluságának kulcsfontosságú eszköze. Idő emberi és élő erő megtakarítása hidraulikus és mechanikus változatban minden géptípusra ',
          'A kotrógép sokoldaluságának kulcsfontosságú eszköze',
          'Idő, emberi és gépi erő megtakarítására',
          'Hidraulikus és mechanikus változatban is!',
          'Minden géptípusra'
        ],
        usage: 'Több felhasználási célra alkalmazott kotrógépekre ajánljuk, sűrű eszközcsere esetén. A mai fejlődő világban szinte alapfelszerelés egy kotróhoz.',
        text: 'A hidraulikus kotró gyorscserélő lehetővé teszi a kotrószerszámok gyors cseréjét, ami jelentősen növeli a gép sokoldalúságát. Az egyedi igények szerint gyártott eszköz magas minőségű és tartós. Segítségével gyorsabban végezheti el a különböző földmunkákat, mindezt a legmagasabb szintű hatékonysággal. Válassza a hidraulikus kotró gyorscserélőt a rugalmas és gyors munkavégzésért!'
      },
      {
        id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
        name: 'Hidraulikus Rakodó gyorscserélő',
        properties: [
          'A rakodógép többcélú felhasználását teszi lehetővé.',
        ],
        usage: 'Többféle felhasználású rakodógépek esetén ajánljuk, sűrű eszközcsere esetén.',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'balavilla',
        name: 'Bálavilla',
        properties: [
          'Saját profilú szerelék',
          'Egyszerű kialakítás és költséghatékony árképzés',
          'Cserélhető tüskékkel',
          'Bármilyen felfogatásra'
        ],
        usage: 'Mezőgazdasági iparágnak ajánljuk bála rakodásra.',
        text: 'A bálavilla kiválóan alkalmas szalmabálák, szénabálák és más mezőgazdasági anyagok mozgatására és rakodására. Az egyedi igények szerint gyártott eszköz megbízható és tartós megoldást kínál. Megkönnyíti és hatékonyabbá teszi a bálák kezelését mindennapi használat során.',
        noOfRefPics: 1
      },
      {
        id: 'tragyavilla',
        name: 'Trágyavilla',
        properties: [
          'Masszív, egyszerű test S355 szerkezeti acélból',
          'Cserélhető villasorral',
          'Hidraulikusan ráfogós krokodil kivitelben',
          'Egyszerű, alsó villasoros változatban is elérhető'
        ],
        usage: 'Mezőgazdasági munkálatokra, trágya rakodásra és szálas növényi hulladék rakodására ajánljuk.',
        text: 'A trágyavilla kiválóan alkalmas trágyakezelésre és -rakodásra a mezőgazdasági területeken. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Segítségével könnyedén és hatékonyan végezheti el a trágyamozgatási feladatokat. Legyen Ön is részese a hatékony mezőgazdasági gépesítés fejlődésének.'
      },
      {
        id: 'konnyuanyag-kanal',
        name: 'Könnyűanyag kanál',
        properties: [
          'Lefolyás gátló kanálprofil,Hardox 500 vágóél,nagy űrtartalom felesleges súly nélkül, csavarozható vágóél opcióval',
          'Lefolyás gátló kanálprofil',
          'HARDOX 500-as vágóél',
          'Nagy űrtartalom, felesleges súly nélkül',
          'Csavarozható vágóél opcióval'
        ],
        usage: 'Mezőgazdasági szóródó anyagok, szemes takarmány nagytömegű rakodására ajánljuk, fix és magasürítésű kivitelben egyaránt.',
        text: 'A könnyűanyag kanál ideális könnyű anyagok, például faforgács, komposzt és szemét rakodására és mozgatására. Az eszköz magas minőségű és kiemelkedő kopásállóságú, biztosítva a hosszú élettartamot. Tökéletes választás a hatékony és precíz munkavégzéshez. Válassza a könnyűanyag kanalat a hatékony anyagmozgatási feladatokhoz!'
      },
      {
        id: 'magasuritesu-kanal',
        name: 'Magasürítésű kanál',
        properties: [
          'Masszív kanáltest',
          'HARDOX 500-as vágóéllel',
          'Egyedi, lefolyás gátló kialakítással',
          'Valós köbméter számolással'
        ],
        usage: 'Magasban történő ürítési feladatokra ajánljuk.',
        text: 'A magasürítésű kanál a legnagyobb ipari feladatokra tervezett eszköz, amely lehetővé teszi a nagy mennyiségű anyag gyors és hatékony mozgatását. A robusztus kialakítás és az extra erősített szerkezet biztosítja a maximális teljesítményt még a legnehezebb terhelés esetén is. A magas minőségű anyagból készült kanál kiemelkedő kopásállóságot garantál, hosszú élettartamot biztosítva a folyamatos használat során.\n' +
            'Ne engedje, hogy a nagy feladatok megállítsák - válassza a magasürítésű kanalat a hatékonyság és megbízhatóság érdekében! Vezesse munkaterülete teljesítményét egy olyan eszközzel, amely biztosítja a legnagyobb termelékenységet és eredményességet minden körülmények között!'
      },
      {
        id: 'raklapvilla',
        name: 'Raklapvilla',
        properties: [
          'Széles teherbírási skála',
          'Minden géptípusra nem csak rakodógépra gyártjuk',
          'CE tanusítvány',
        ],
        usage: 'Raklapos árú mozgatására ajánljuk elsősorban. Valamint bármilyen egyéb tárgy mozgatására ami a teherbírásnak megfelel.',
        text: 'A raklapvilla ideális raklapok és különböző rakományok biztonságos és hatékony mozgatására. Az eszköz magas minőségű, megbízható és tartós. Az egyedi igények szerint készült szerelék segítségével egyszerűbben és precízebben végrehajthatók a rakodási feladatok. Válassza a raklapvillát a kiváló minőség és hatékonyság érdekében!'
      },


      //BLOWING MACHINES
      {
        id: 'pap1eco',
        category: 'blowingMachine',
        name: 'PAP-1 ECO',
        image: 'PAP-1-ECO.webp',
        values: ['1', '0.75 L', '800'],
        overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztetve.  Csak ez az ECO típus rendelhető  akár félautomata üzemeltetésű kivitelben is, kézi előforma adagolással és kézi flakon kivétellel. Egyszerű felépítésű gép.'
      },
      {
        id: 'pap1',
        category: 'blowingMachine',
        name: 'PAP-1',
        image: 'PAP-1.webp',
        values: ['1', '0.75 L', '900'],
        overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztve. Ez a géptípus teljesen automata kivitelben készül. A gép vezérlése alkalmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. '
      },
      {
        id: 'pap1hotFill',
        category: 'blowingMachine',
        name: 'PAP-1 HOT FILL',
        image: 'PAP-1.webp',
        values: ['1', '0.75 L', '650'],
        overlayText: 'A PAP-1 tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyártására.'
      },
      {
        id: 'pa1eco',
        category: 'blowingMachine',
        name: 'PA-1 ECO',
        image: 'dummy_300x150.webp',
        values: ['1', '2.25 L', '800'],
        overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. Ez a géptípus teljesen autpomata üzemmódú kivitelben készül. A gép vezérlése alaklmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy  felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. Egyszerű felépítésű gép.'
      },
      {
        id: 'pa1hotFill',
        category: 'blowingMachine',
        name: 'PA-1 HOT FILL',
        image: 'dummy_300x150.webp',
        values: ['1', '2.25 L', '650'],
        overlayText: 'A PA-1 ECO tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyárátsára.'
      },
      {
        id: 'pe15uni',
        category: 'blowingMachine',
        name: 'PE-15 UNI',
        image: 'PE-15-UNI.webp',
        values: ['1', '6 L', '1300'],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pe110uni',
        category: 'blowingMachine',
        name: 'PE-110 UNI',
        image: 'PE-110-UNI.webp',
        values: ['1', '12 L', '1200',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.   A gép  kimondottan a nagyobb űrtartalmű flakonok gyártására  lett kifejlesztve.'
      },
      {
        id: 'pap2',
        category: 'blowingMachine',
        name: 'PAP-2',
        image: 'PAP-1.webp',
        values: ['2', '0.3 L', '1800',],
        overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztve. Ez a géptípus teljesen automata kivitelben készül. A gép vezérlése alkalmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. A gép a PAP-1 géptípussal azonos méretű, de egyszerre két db kisebb űrtartalmú flakotn tud elkészíteni.'
      },
      {
        id: 'pa2ecoplus',
        category: 'blowingMachine',
        name: 'PA-2 ECO+',
        image: 'PA-2-ECO.webp',
        values: ['2', '2 L', '1400',],
        overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. Ez a géptípus teljesen autpomata üzemmódú kivitelben készül. A gép vezérlése alaklmas rá, hogy egy töltőgépet on-line módban kiszolgáljon,  a töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet. Az ECO⁺ típus a pneumatikus működésű gépek  korszerűsített, modernizált energiatakarékos modellje.'
      },
      {
        id: 'pe2uni',
        category: 'blowingMachine',
        name: 'PE-2 UNI',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.webp',
        values: ['2', '2.5 L', '3200',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pe2uniHotFill',
        category: 'blowingMachine',
        name: 'PE-2 UNI HOT FILL',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.webp',
        values: ['2', '2.5 L', '1800',],
        overlayText: 'A PE-2 tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyárátsára.'
      },
      {
        id: 'pe25uni',
        category: 'blowingMachine',
        name: 'PE-25 UNI',
        image: 'PE-25-UNI-és-PA-210.webp',
        values: ['2', '6 L', '2500',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pa210uni',
        category: 'blowingMachine',
        name: 'PA-210 UNI',
        image: 'PE-25-UNI-és-PA-210.webp',
        values: ['2', '12 L', '2000 bph',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pe4uni',
        category: 'blowingMachine',
        name: 'PE-4 UNI',
        image: 'PE-4-UNI.webp',
        values: ['4', '2.5 L', '6400',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pe6uni',
        category: 'blowingMachine',
        name: 'PE-6 UNI',
        image: 'dummy_300x150.webp',
        values: ['6', '2.5 L', '9200 bph',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
      },
      {
        id: 'pe12uni',
        category: 'blowingMachine',
        name: 'PE-12 UNI',
        image: 'PE-12-UNI.webp',
        values: ['12', '0.06 L', '12000',],
        overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcslád 12 fészkes kivitele kimondottan kis űrtartalmű flakonok gyártására kifejlesztett célgép.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre.'
      },

    ],
    //#endregion
    //IMAGE ALTS
    //#region
    imgAlt0:'Poliol Logo',
    imgAlt1:'Markoló üríti rakományát.',
    imgAlt2:'Flakonfúvó gép váz rajz',
    imgAlt3:'Víz fröcsköl egy vizesflakonból',
    imgAlt4:'Világtérkép',
    imgAlt5:'Markoló kanállal',
    imgAlt6:'Hegesztés',
    imgAlt7:'Gyártott bányakanál',
    imgAlt8:'Robothegesztés',
    imgAlt9:'Fehér flakonok sorakoznak',
    imgAlt10:'Alakadó szerszám gyártása',
    imgAlt11:'Flakon tervezése',
    imgAlt12:'Futurisztikus vizes flakon terv koncepció',
    imgAlt13:'',
    imgAlt14:'',
    imgAlt15:'',
    imgAlt16:'',
    imgAlt17:'',
    imgAlt18:'',
    imgAlt19:'',
    imgAlt20:'',
    imgAlt21:'',
    imgAlt22:'',
    //#endregion
    //PRIVACY POLICY, DATA PROTECTION
    //#region
    dp1:'Adatvédelem',
    dp2:'Adatvédelmi és adatkezelési szabályzat GDPR',
    cp1:'Panaszkezelés',
    //#endregion
        //TENDERS
    //#region
    t1:'Aktuális pályázatok és projektek',
    t2:'A projekt azonosító száma:',
    t3:'GINOP_PLUSZ-1.2.3-21-2022-01788',
    t4:'Kedvezményezett:',
    t5:'Poliol Kft.',
    t6:'Projekt címe:',
    t7:'Komplex fejlesztés megvalósítása a Poliol Kft.-nél',
    t8:'Sikeresen megvalósult pályázatok',
    t9:'Bővebben',
    t10:'Támogatás összege:',
    t11:'Támogatás intenzitása:',
    t12:'Fejlesztés célja:',
    t13:'A projekt tervezett megvalósulásának időpontja:',
    t14:'',
    tenderInfo:[
      {id:'GINOP-3.2.2-8-2-4-16-2020-02008', p1:'22 269 208,-Ft',p2:'55%',p3:'A folytonos kapacitás bővítés mellett fontos a legtöbb folyamatunk monitorozása, az anyagi és humán kapacitásaink hatékonyságának mérése, ezért tervezzük a már elavult ERP rendszerünket GDSERP BEST PRO Integrált ügyviteli rendszerre cserélni. Az új hybrid ERP részeként 11 modult fogunk megvenni, a dobozos terméket az igényeinknek megfelelően testre szabják.  A bővülő tervezési faladatok gyorsítása érdekében vált szükségessé a Solid Edge 3D CAD szoftver vásárlás.',p4:'A projekt keretében történik a számítógéppark megerősítése és bővítése (szervergép, tervezői munka állomások monitorokkal, a wi-fi hálózat biztonságát, teljesítményét javító routerek, szünetmentes tápok, külső adattárak, laptopok és Zebra beolvasó, vonalkód nyomtató eszközök).',p5:'2022.03.31.'},
      {id:'GINOP_PLUSZ-1.2.3-21-2022-01788', p1:'144 200 000 Ft feltételesen visszatérítendő támogatás',p2:'70%',p3:'A projekt keretében egy fémmegmunkáló csarnokot tervezünk, amelyben az anyagmozgatási/ daruzási feladatokat egy 10t két főtartós híddaru fogja ellátni. Azért, hogy korszerű munkakörülményeket tudjunk biztosítani, egy levegő-víz üzemelésű rendkívül modern, energiahatékony – hőszivattyús – hűtő-fűtő klimatizáló berendezés is beépítésre kerül.\n\nTovábbá tervezünk vásárolni egy 2200x6400mm asztalméretű (vágóképességű) síkágyas plazmavágógépet, ami a vastaglemezek (15 mm-nél vastagabb lemezek) vágását is képes elvégezni.',p4:'A munkatársak kompetencia fejlesztést egy szervezetfejlesztési képzés keretein belül tervezzük kivitelezni, emellett a termelésirányítási rendszer fejlesztési mérföldköveinek, javaslatainak meghatározásához, egy több mint 25 éves szakmai tapasztalattal bíró informatikai vállalatot bízunk meg a tanácsadási feladatok ellátására.',p5:'2024.02.15.'},
      {id:'GINOP-1.2.10-19-2019-00135', p1:'39 894 000,-Ft',p2:'70%',p3:'Flakonfúvó gép gyártás területén több mint 17 évnyi, a flakon gyártás területén pedig 28 évnyi tapasztalattal rendelkezünk. Tapasztalatainkra alapozva elmondhatjuk, hogy a folyamatos termék innováció elengedhetetlen a vevők visszajelzései és a piaci trendek alapján. Az innovatív PE UNI gépcsaládunk aktuális fejlesztésének egyik innovatív iránya egy gyorsabb működésű gép, amellyel egyenletesebb palack falvastagságot tudunk elérni. Az egyenletes falvastagság kisebb anyagfelhasználás mellett is jobb minőségű palack fúvását teszi lehetővé és persze olcsóbb termék előállítást.\nA fúvógépeink összetett geometriájú vázának hegesztését automatizálni kívánjuk. Kiemelt hangsúlyt fektetünk a váz anyagának vastagságára és a kiváló hegesztésre, ezért egyedi tervezésű félautomata hegesztő robotot gyártattunk. A fúvógép váz méretei (3,7×2,2×3,3m) indokolják a forgató 3 t teherbírását, a max. 6 m hosszú és 3 m széles befogási méreteit.',p4:'Egyedi kialakítású a robotkar 6 tengelyes mozgása, a pálya hossza is. Az egyedi gyártású alkatrészeink rögzítését, gyors és pontos cserélhetőséget biztosító befogó sablonokkal készült a robot. Az egyedi gyártás miatti kézi beavatkozási lehetőség indokolja a félautomata működési módot, így kézzel is indítható a robot, ha bonyolultabb feladatot kell végezni.',p5:'2020.10.31.'},
      {id:'GINOP-2.1.8-17-2018-03529', p1:'28 902 457,-Ft',p2:'50%',p3:'Flakonfúvó gép gyártás területén több mint 17 évnyi, a flakon gyártás területén pedig 28 évnyi tapasztalattal rendelkezünk. Tapasztalatainkra alapozva elmondhatjuk, hogy a folyamatos termék innováció elengedhetetlen a vevők visszajelzései és a piaci trendek alapján. Az innovatív PE UNI gépcsaládunk aktuális fejlesztésének egyik innovatív iránya egy gyorsabb működésű gép, amellyel egyenletesebb palack falvastagságot tudunk elérni. Az egyenletes falvastagság kisebb anyagfelhasználás mellett is jobb minőségű palack fúvását teszi lehetővé és persze olcsóbb termék előállítást. A fejlesztett gépek vázát és külső burkolatát eddig üzemen kívül festettük. A projekt részeként megvásárolt VANEX SPR-103 típusú Festő-szikkasztó-szárító kabin beszerzésével üzemen belülre tudjuk hozni a munkát.\nA kalibráló (univerzális bemérő) segítségével a gép különböző alkatrészeinek megmunkálása válik pontosabbá, mivel e berendezésnek köszönhetően a marógép automatikus veszi fel a méretpontos pozíciókat, majd végzi a további megmunkálást.',p4:'A LORCH S5 Speed Pulse XT hegesztő innovatív egyedi beolvasztási tulajdonsága erős és esztétikus varratok készítését teszi lehetővé. A nagy sebességű működés az érintett alkatrészeket, azok varratait jobban igénybe veszi. Ezért szükséges a megfelelő varratot biztosító hegesztő gép beszerzése.',p5:'2019.10.15.'},
      {id:'GINOP-1.3.1-15-2016-00339', p1:'17 520 934 Ft',p2:'50%',p3:'A projektnek köszönhetően nagyobb hangsúlyt tud cégünk helyezni a Nyugat-Európai piacokra, elsősorban Németországra. A projektben két németországi kiállításokon való részvétel valósul meg.',p4:'ermelési kapacitások komplex bővítése\nKapacitásbővítő beruházás\nTelephely és technológia fejlesztés\nKomplex vállalati technológia fejlesztés\nTechnológiai fejlesztés\nTelephely fejlesztés\nVállalati innováció támogatása\nInnovációs eredmények hasznosítása',p5:'2017.11.30.'}
    ],
    //#endregion
        //rPet MODAL
    //#region
    rpet1:'Felhívjuk kedves megrendelőink figyelmét, hogy jövőbeni alapanyag igényeiket a 2025.01.01-től életbe lépő rPET palackokkal kapcsolatos törvényi szabályozásokat figyelembe véve szíveskedjenek leadni.',
    rpet2:'A jogszabály hivatkozása',
    rpet3:'Az Európai Parlament és a Tanács irányelve egyes műanyagtermékek környezetre gyakorolt hatásának csökkentéséről',
    rpet4:'A jogszabályban előírtak teljesítése nem a POLIOL Kft., mint gyártó, hanem a forgalomba helyező felelőssége, ezáltal igény esetén cégünk továbbra is gyárt virgin PET palackokat.',
    rpet5:'Köszönettel,',
    rpet6:'ide kattintva,',
    rpet7:'ezen az elérésen',
    rpet8:'elérhető',
    rpet9:'található',
    //#endregion


  }